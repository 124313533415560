import styled from "styled-components";
import Modal from 'react-modal';


const Image = styled.img`
max-width: 100%;   

`;

const StyledModal = styled(Modal)`
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin: auto;
display: flex;
justify-content: center;
align-items: center;
outline: none;
overflow: auto;
width: 25%;

`;

const CloseButton = styled.button`
  position: absolute;
  top: 130px;
  right: 10px;
  background: #e3caaa;
  color: black;
  border: none;
  border-radius: 50%;
  font-size: 1.2rem;
  line-height: 1;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.2s ease-in-out;

  &:hover {
    background: #deba8c;
  }
`;

export { Image, StyledModal, CloseButton };
