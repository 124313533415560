import React from "react";
import { Image, StyledModal, CloseButton } from "./ImgModalStyles";

export default function ImgModal({ isOpen, setIsOpen, src }) {
  return (
    <StyledModal
      isOpen={isOpen}
    >
      <Image src={src} />
      <CloseButton onClick={() => setIsOpen(false)}>X</CloseButton>
    </StyledModal>
  );
}
